import React from "react";
import { Fragment } from "react";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";

function ContactForm() {
  const [isSubscribed, setIsSubscribed] = useState(true);

  const handleChange = (event) => {
    setIsSubscribed(event.target.checked);

    // this is the checkbox itself
    console.log(event.target);

    // this is the checked value of the field
    console.log(event.target.checked);
  };

  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5j4z99a",
        "template_9xr7ja6",
        form.current,
        "XPtc44xqtpXUx-AS8"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    navigate("/thank-you");
  };

  return (
    <Fragment>
      <form ref={form} onSubmit={sendEmail} className="col s12">
        <div className="input-field col s12 l6">
          <input
            name="first_name"
            id="first_name"
            type="text"
            className="validate"
          />
          <label htmlFor="first_name">First Name</label>
        </div>
        <div className="input-field col s12 l6">
          <input
            name="last_name"
            id="last_name"
            type="text"
            className="validate"
          />
          <label htmlFor="last_name">Last Name</label>
        </div>
        <div className="input-field col s12">
          <input name="email" id="email" type="email" className="validate" />
          <label htmlFor="email">Email</label>
        </div>
        <div className="input-field col s12">
          <input id="phone" name="phone" type="text" className="validate" />
          <label htmlFor="phone">Phone</label>
        </div>
        <div className="input-field col s12 pa2">
          <input name="message" id="message" type="text" className="validate" />
          <label htmlFor="message" className="">
            Message{" "}
          </label>
        </div>
        <label>
          <div className="input-field col s12 l8">
            <input
              type="checkbox"
              name="subscription"
              onChange={handleChange}
              checked={isSubscribed}
              id="subscription"
            />
            <span>yes, I would like to receive email updates</span>
          </div>
        </label>
        <button
          className="secondary-btn right waves-effect waves-green"
          type="submit"
          name="action"
          id="send"
        >
          Send
        </button>
      </form>
    </Fragment>
  );
}

export default ContactForm;
