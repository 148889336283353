import React from "react";
import { Fragment } from "react";
import ContactForm from "../forms/ContactForm";

function ContactContent() {
  return (
    <Fragment>
      <article className="vh-75 dt w-100 white-bg mv4">
        <div className="dtc v-mid tc ph3 ph4-l">
          <div className="container tl">
            <div className="row">
              <div className="col s12 m12 l6 xl6">
                <h1 className="mb4 mt2 f3 f2-m f1-l">Contact Us Today</h1>
                <p className="mb4 f5 f4-m f3-l">
                  {" "}
                  Have a question? We'd love to answer it! Please send us a
                  message via our contact form.{" "}
                </p>
              </div>
              <div className="col s12 m12 l6 xl6" id="form-container">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </article>
    </Fragment>
  );
}

export default ContactContent;
