import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function Home() {
  const { useEffect, useRef } = React;
  // store a reference to the box div
  const logo = useRef();
  const nav = useRef();
  const menu = useRef();
  const introImg = useRef();
  const introImgContain = useRef();
  const stoppingHouseImg1 = useRef();
  const stoppingHouseImg2 = useRef();
  const stoppingHouseImg3 = useRef();
  const stoppingHouse = useRef();
  const stoppingHouse2 = useRef();
  const stoppingHouse3 = useRef();
  const stoppingHouse4 = useRef();
  const stoppingHouse5 = useRef();
  const stoppingHouse6 = useRef();
  const border1 = useRef();
  const border2 = useRef();
  const border3 = useRef();
  const border4 = useRef();
  const border5 = useRef();
  const border6 = useRef();
  const border7 = useRef();
  const text1 = useRef();
  const text2 = useRef();
  const text3 = useRef();
  const text4 = useRef();
  const text5 = useRef();
  const coord = useRef();
  const sticker1 = useRef();
  const sticker2 = useRef();
  const sticker3 = useRef();
  const sticker4 = useRef();
  const sticker5 = useRef();
  const smokey = useRef();
  const pinecreek1 = useRef();
  const pinecreek2 = useRef();
  const pinecreek3 = useRef();
  const exp = useRef();
  const bigImg = useRef();
  const heartland = useRef();
  const book = useRef();
  const smokey2 = useRef();
  const rooms = useRef();

  // wait until DOM has been rendered
  useEffect(() => {
    gsap.from(nav.current, {
      // yPercent: -100,
      opacity: 0,
      duration: 0.5,
      delay: 1,
      ease: "power.2",
    });

    gsap.to(logo.current, {
      opacity: 1,
      duration: 1.5,
      delay: 2,
      ease: "power.2",
    });

    gsap.to(menu.current, {
      opacity: 1,
      duration: 1.5,
      delay: 2,
      ease: "power.2",
    });

    gsap.from(introImgContain.current, {
      opacity: 0,
      duration: 1,
      delay: 2.5,
      ease: "power.2",
    });

    gsap.to(introImg.current, {
      opacity: 1,
      duration: 1.3,
      delay: 3.3,
      ease: "power.2",
    });

    gsap.from(stoppingHouse.current, {
      scrollTrigger: {
        trigger: ".anim1",
        start: "bottom center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1,
    });

    gsap.from(stoppingHouseImg1.current, {
      scrollTrigger: {
        trigger: ".anim4",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1,
    });

    gsap.from(border1.current, {
      scrollTrigger: {
        trigger: ".anim1",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(text1.current, {
      scrollTrigger: {
        trigger: ".anim2",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(coord.current, {
      scrollTrigger: {
        trigger: ".anim2",
        start: "center center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(border2.current, {
      scrollTrigger: {
        trigger: ".anim2",
        start: "bottom center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(sticker1.current, {
      scrollTrigger: {
        trigger: ".anim3",
        start: "center center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(border3.current, {
      scrollTrigger: {
        trigger: ".anim3",
        start: "center+=70px center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(stoppingHouse2.current, {
      scrollTrigger: {
        trigger: ".anim5",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(stoppingHouseImg2.current, {
      scrollTrigger: {
        trigger: ".anim5",
        start: "top+=10px center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(stoppingHouse3.current, {
      scrollTrigger: {
        trigger: ".anim5",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(stoppingHouse4.current, {
      scrollTrigger: {
        trigger: ".anim5",
        start: "top+=20px center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //   markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(stoppingHouse5.current, {
      scrollTrigger: {
        trigger: ".anim5",
        start: "top+=200px center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //   markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(stoppingHouse6.current, {
      scrollTrigger: {
        trigger: ".anim5",
        start: "top+=300px center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(stoppingHouseImg3.current, {
      scrollTrigger: {
        trigger: ".stoppingHouseImg3",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(sticker2.current, {
      scrollTrigger: {
        trigger: ".anim5",
        start: "center center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(smokey.current, {
      scrollTrigger: {
        trigger: ".anim6",
        start: "top+=10% center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(pinecreek1.current, {
      scrollTrigger: {
        trigger: ".anim6",
        start: "top+=10% center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(pinecreek2.current, {
      scrollTrigger: {
        trigger: ".anim6",
        start: "top+=20% center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(pinecreek3.current, {
      scrollTrigger: {
        trigger: ".anim6",
        start: "top+=30% center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(sticker3.current, {
      scrollTrigger: {
        trigger: ".anim6",
        start: "center center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(sticker4.current, {
      scrollTrigger: {
        trigger: ".anim6",
        start: "center center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(border4.current, {
      scrollTrigger: {
        trigger: ".anim7",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(exp.current, {
      scrollTrigger: {
        trigger: ".exp",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(text2.current, {
      scrollTrigger: {
        trigger: ".text2",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(border5.current, {
      scrollTrigger: {
        trigger: ".anim8",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(bigImg.current, {
      scrollTrigger: {
        trigger: ".bigImg",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(smokey2.current, {
      scrollTrigger: {
        trigger: ".smokey2",
        start: "top+=6% center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(text3.current, {
      scrollTrigger: {
        trigger: ".smokey2",
        start: "top+=10% center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(text4.current, {
      scrollTrigger: {
        trigger: ".smokey2",
        start: "top+=20% center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(text5.current, {
      scrollTrigger: {
        trigger: ".smokey2",
        start: "top+=30% center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(rooms.current, {
      scrollTrigger: {
        trigger: ".smokey2",
        start: "center center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(sticker5.current, {
      scrollTrigger: {
        trigger: ".smokey2",
        start: "center center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        //  markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(border6.current, {
      scrollTrigger: {
        trigger: ".anim9",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(heartland.current, {
      scrollTrigger: {
        trigger: ".pine",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(border7.current, {
      scrollTrigger: {
        trigger: ".anim10",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });

    gsap.from(book.current, {
      scrollTrigger: {
        trigger: ".book",
        start: "top center", // when the top of the trigger hits the top of the viewport
        ease: "power.2",
        // markers: true,
      },
      opacity: 0,
      duration: 1.2,
    });
  });

  return (
    <Fragment>
      <nav className="z-depth-1" ref={nav}>
        <div className="nav-wrapper">
          <Link to="/" className="brand-logo center">
            <img
              ref={logo}
              className="mt4 o-0"
              src="https://storage.googleapis.com/corecdn-public/54801.png"
              width={180}
              alt="stopping house logo"
            />
          </Link>
          <Link to="/" data-target="mobile-demo" className="sidenav-trigger">
            <i className="material-icons o-0" ref={menu}>
              menu
            </i>
          </Link>
        </div>
      </nav>

      <article className="mt6-ns mt4 introImg">
        <div
          ref={introImgContain}
          className="row img-frame vh-100-l mh5-l mh4-m mh3 valign-wrapper br3"
        >
          <div className="col shadow-box-l br3 center o-0" ref={introImg}>
            <img
              className="mv6 mh4-l shadow-img-l"
              src="https://storage.googleapis.com/corecdn-public/14918Layer%201.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="row anim1">
          <div className="col m12 l12 s12 xl12">
            <p className="center f5 f4-m f3-l" ref={stoppingHouse}>
              Pine Creek Stopping House C 1994
            </p>
          </div>
        </div>
      </article>

      <div className="row bb b-gray pt3 border-b-w" ref={border1}>
        <div className="col l6 m6 s6 xl6 border-r-w br b-gray pa4 border-h"></div>
        <div className="col l6 m6 s6 xl6 border-l-w bl b-gray pa4 border-h"></div>
      </div>

      <article className="anim2">
        <div className="row">
          <div className="col xl12 s12 m12 l12">
            <p
              className="center f5 f3-m f2-l measure-narrow center fw6"
              ref={text1}
            >
              THE HISTORICAL PINE CREEK STOPPING HOUSE IS PART OF A HIDDEN
              RETREAT NESTLED ABOVE THE BANKS OF THE NORTH SASKATCHEWAN RIVER
              ALONG OLD VICTORIA TRAIL. THE STOPPING HOUSE SERVED AS A SANCTUARY
              AND RE-SUPPLY FOR WEARY TRAVELERS, HOMESTEADERS AND CURIOUS
              ADVENTURISTS RETREATING INTO THE WILD OF THE ALBERTA HEARTLAND AND
              LAKELAND.
            </p>
            <p className="center pv4 fw6" ref={coord}>
              54°03'22.5"N 112°43’37.3"W
            </p>
          </div>
        </div>
      </article>

      <div ref={border2} className="row bt b-gray pb3 border-t-w w-30">
        <div className="col xl6 l6 m6 s6 border-r-w br b-gray pa4 border-h"></div>
        <div className="col xl6 l6 m6 s6 border-l-w bl b-gray pa4 border-h"></div>
      </div>

      <section className="mh4-m mh3 anim3">
        <div className="row relative">
          <div className="col xl3 l3 s12 absolute bottom-0 left-0 hide-on-med-and-down">
            <div className="pl5-xl pl4 pl3-m" ref={sticker1}>
              <p className="pr6 measure-narrow">
                Pine Creek Stopping House, Alberta Provincial Heritage Site C
                2011
              </p>
              <div className="stickerLink">
                <a
                  href="https://hermis.alberta.ca/ARHP/Details.aspx?DeptID=1&ObjectID=4665-0709"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://storage.googleapis.com/corecdn-public/19157sticker.jpg"
                    alt=""
                  />
                </a>
                <div className="hoverSticker"></div>
                <div className="explore">
                  <p className="tc">explore</p>
                </div>
              </div>
            </div>
          </div>
          <div
            ref={stoppingHouseImg1}
            className="col xl6 l6 offset-xl3 s12 offset-l3 m12 img-frame anim4 center br3 z--1"
          >
            <div className="mv6">
              <img
                className="shadow-img-l"
                src="https://storage.googleapis.com/corecdn-public/13854Layer%202.jpg"
                alt=""
              />
              <p className="light-grn pt4 ">
                View of North Saskatchewan River C 1912
              </p>
            </div>
          </div>
          <div className="col xl3 l3 hide-on-med-and-down" />
        </div>
      </section>

      <div
        className="row bb b-gray pt3 border-b-w z-max relative top--3"
        ref={border3}
      >
        <div className="col l6 m6 s6 xl6 border-r-w br b-gray pa4 border-h"></div>
        <div className="col l6 m6 s6 xl6 border-l-w bl b-gray pa4 border-h"></div>
      </div>

      <article className="pb4 bb b-gray border-b-w p-b-0 anim5">
        <div className="row relative">
          <div className="col xl6 l12 m12 self-center ph-0">
            <p className="mv1 mv4-l mh5-xl mh4-m mh3" ref={stoppingHouse2}>
              Pine Creek Stopping House C 1994
            </p>
            <div
              className="img-frame br3 mv1-l mv3 mh5-xl mh4-m mh3"
              ref={stoppingHouseImg2}
            >
              <div className="center pv7-l pv6">
                <img
                  className="shadow-img-l w-80-s"
                  src="https://storage.googleapis.com/corecdn-public/17919Layer%203.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col xl4 l9 m12 center ph-0">
            <div className="tj ph5-xl ph4-m ph3">
              <h2 className="f5 f3-m f3-l measure fw4" ref={stoppingHouse3}>
                Pine Creek Stopping House
              </h2>
              <p className="f5 f4-m f3-l" ref={stoppingHouse4}>
                Commencing winter 2021, the historical Pine Creek Stopping house
                and grounds are being carefully restored and resurrected to
                serve its original purpose and intend for the modern curious
                adventurer wanting to connect to the rich cultural history and
                breathtaking landscapes of Alberta’s Lakeland Region.
              </p>
              <p className="f5 f4-m f3-l" ref={stoppingHouse5}>
                The Pine Creek Stopping House is a one-and-a-half storey log
                house originally built in 1908. This designated provincial
                historical resource was originally used as an Inn, general
                store, post office and social gathering area for the region. It
                features a steeply pitched gable roof, wrap-around veranda and
                one-storey lean-to addition at the rear.
              </p>
              <p className="f5 f4-m f3-l" ref={stoppingHouse6}>
                A modern wing with extra accommodation, great room and wood
                burning fireplace were added in the early 1990s, and now serve
                as part of the Pine Creek Retreat facilities.
              </p>
              <img
                ref={stoppingHouseImg3}
                className="responsive-img w-100 stoppingHouseImg3"
                src="https://storage.googleapis.com/corecdn-public/10264StoppingHouse_Farm2table_LittleGirl_LOWRES.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="col xl2 l3 hide-on-med-and-down absolute bottom-0 right-0 ml-0">
            <div className="center row" ref={sticker2}>
              <div className="col">
                <p className="measure-narrow pr5 tl">
                  Pine Creek Stopping House is located on the Pine Creek Retreat
                  Grounds
                </p>
              </div>
              <div className="col">
                <img
                  src="https://storage.googleapis.com/corecdn-public/11476sticker2.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="mh4-m mh3 anim6">
        <div className="row">
          <div className="col xl6 l5 m12 ph-0">
            <div className="ml5-xl">
              <h2
                className="f5 f3-m f3-l measure-l left-align-l fw4 mt4"
                ref={pinecreek1}
              >
                Gateway to the Lakeland
              </h2>
              <p
                className="f5 f4-m f3-l measure-l left-align-l"
                ref={pinecreek2}
              >
                The property is situated in a natural setting on Pine Creek
                Ravine, with a large yard and numerous trees alongside the
                historic Victoria Trail, near the village of Waskatenau on the
                North side of the North Saskatchewan River, only minutes away
                from newly re-imagined Metis Crossing Cultural Destination and
                Historical Victoria Settlement.
              </p>
              <p
                className="f5 f4-m f3-l measure-l left-align-l"
                ref={pinecreek3}
              >
                Pine Creek Stopping House and Pine Creek Retreat are located in
                the county of Smoky Lake, the gateway to the Lakeland region. We
                are proud to be part of the community of Smoky Lake, as the
                county and residents are committed to identifying and preserving
                its vast and rich historic and heritage assets, and is home to
                the Re-imagined Metis Crossing Cultural Destination, numerous
                Provincial and Municipal Historic Resources, including Pine
                Creek, as well as the Victoria District National Historic Site
                of Canada. Each of these buildings and sites are important to
                the fabric and history of Smoky Lake County and Lakeland Region.
              </p>

              <div className="row">
                <div className="col pl-1">
                  <div ref={sticker3} className="stickerLink">
                    <a
                      href="https://ironhorsetrail.ca/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="mr1"
                        src="https://storage.googleapis.com/corecdn-public/11350sticker3.jpg"
                        alt=""
                      />
                    </a>
                    <div className="hoverSticker"></div>
                    <div className="explore">
                      <p className="tc">explore</p>
                    </div>
                  </div>
                  <div ref={sticker4} className="stickerLink">
                    <a
                      href="https://metiscrossing.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="ml1"
                        src="https://storage.googleapis.com/corecdn-public/15081sticker4.jpg"
                        alt=""
                      />
                    </a>
                    <div className="hoverSticker"></div>
                    <div className="explore">
                      <p className="tc">explore</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col xl6 l7 m12 ph-0" ref={smokey}>
            <p className="mt4">Town of Smoky Lake C 1930-40</p>
            <div className="pr4-l pr0 pb3">
              <img
                className="w-100 mb2"
                src="https://storage.googleapis.com/corecdn-public/9443Layer%204.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </article>

      <div className="row bt b-gray pb3 border-t-w anim7" ref={border4}>
        <div className="col l6 m6 s6 xl6 border-r-w br b-gray pa4 border-h"></div>
        <div className="col l6 m6 s6 xl6 border-l-w bl b-gray pa4 border-h"></div>
      </div>

      <article className="mv5">
        <div className="row">
          <div className="col xl12 l12 m12 s12">
            <p className="center-align f4 lh-copy mb2 fw6 exp" ref={exp}>
              EXPERIENCE
            </p>
            <p
              className="center f5 f3-m f2-l measure center fw6 text2"
              ref={text2}
            >
              THE STOPPING HOUSE HISTORICAL ARCHITECTURE HAS BEEN CAREFULLY
              JUXTAPOSED WITH A MINDFUL MODERN BOHEMIAN INTERIOR, DETAILED WITH
              LOCAL AND INDIGENOUS ARTISAN DESIGNS AND ARTIFACTS.
            </p>
          </div>
        </div>
      </article>

      <div ref={border5} className="row bt b-gray pb3 border-t-w w-30-l anim8">
        <div className="col xl6 l6 m6 s6 border-r-w br b-gray pa4 border-h"></div>
        <div className="col xl6 l6 m6 s6 border-l-w bl b-gray pa4 border-h"></div>
      </div>

      <section className="bigImg">
        <div className="mh5-xl mh4-m mh3">
          <img
            ref={bigImg}
            className="w-100"
            src="https://storage.googleapis.com/corecdn-public/6150stoippingn-house-low-resy-2.jpg"
            alt=""
          />
        </div>
      </section>

      <section className="mv5-l mh5-xl mh4-m mh3 smokey2">
        <div className="row relative">
          <div ref={smokey2} className="col xl4 m6 s12 no-pa">
            <div className="mr5-xl mr4-ns mr0">
              <img
                className="w-100"
                src="https://storage.googleapis.com/corecdn-public/13537StoppingHouse_WorkArea.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="col xl6 m6 s12">
            <h2 className="f5 f3-m f3-l mt0 measure" ref={text3}>
              Find Yourself in Pine Creek
            </h2>
            <p className="f5 f4-m f3-l measure" ref={text4}>
              Pine Creek retreat was established around the idea of an immersive
              personal escape. We wanted to share this natural sanctuary and the
              beautiful landscape of this historical gathering place to escape,
              connect and rejuvenate what’s important to you. Situated on the
              Pine Creek Ravine, above the banks of the North Saskatchewan
              River, the retreat property offers a unique connection to nature
              and self with its unique shelter experiences, thoughtfully placed
              along the ravine , all connected to a lavish common area
              facilities, including showers, bathrooms, personal areas, full
              outdoor kitchen, common deck, common area fire pit, wood burning
              cedar oak hot tub and finally the Pine Creek Stopping House and
              Facilities
            </p>
            <Link
              to="contact"
              ref={rooms}
              className="f4 link dim ba ph5-l ph6 pv3 mt3 mb4 dib btn_trans"
              href="#0"
            >
              REGISTER
            </Link>
          </div>
          <div className="col xl2 hide-on-med-and-down absolute bottom-0 right-0">
            <div ref={sticker5} className="right">
              <img
                src="https://storage.googleapis.com/corecdn-public/15209sticker5.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <div ref={border6} className="row bt b-gray pb3 border-b-w anim9">
        <div className="col l6 m6 s6 xl6 border-r-w br b-gray pa4 border-h"></div>
        <div className="col l6 m6 s6 xl6 border-l-w bl b-gray pa4 border-h"></div>
      </div>

      <article className="pine">
        <div className="row">
          <div className="center">
            <h1 className="f2-m f1-l tracked fw6 tc" ref={heartland}>
              FIND YOURSELF IN PINE CREEK
            </h1>
          </div>
        </div>
      </article>

      <div ref={border7} className="row anim10 bb b-gray pt3 border-t-w w-30-l">
        <div className="col xl6 l6 m6 s6 border-r-w br b-gray pa4 border-h"></div>
        <div className="col xl6 l6 m6 s6 border-l-w bl b-gray pa4 border-h"></div>
      </div>
    </Fragment>
  );
}

export default Home;
