import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import ContactContent from "../components/sections/ContactContent";

function Contact() {
  return (
    <Fragment>
      <nav className="z-depth-1">
        <div className="nav-wrapper">
          <Link to="/" className="brand-logo center">
            <img
              className="mt4"
              src="https://storage.googleapis.com/corecdn-public/54801.png"
              width={180}
              alt="stopping house logo"
            />
          </Link>
          <a href="/" data-target="mobile-demo" className="sidenav-trigger">
            <i className="material-icons">menu</i>
          </a>
        </div>
      </nav>

      <ContactContent />
    </Fragment>
  );
}

export default Contact;
