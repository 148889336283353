import React from "react";
import { Fragment } from "react";

function ThankYou() {
  return (
    <Fragment>
      <nav className="z-depth-1">
        <div className="nav-wrapper">
          <a href="/" className="brand-logo center">
            <img
              className="mt4"
              src="https://storage.googleapis.com/corecdn-public/54801.png"
              width={180}
              alt="stopping house logo"
            />
          </a>
          <a href="/" data-target="mobile-demo" className="sidenav-trigger">
            <i className="material-icons">menu</i>
          </a>
        </div>
      </nav>

      <section className="vw-100 thank-you justify-center h-100 flex flex-column overflow-hidden">
        <article className="pa3 pa6-ns mb5">
          <h1 className="f3 f1-m mv3 f-subheadline-l">Thank You!</h1>
          <p className="measure lh-copy f5 f4-m f3-l pl1">
            We will contact you shortly
          </p>
        </article>
      </section>
    </Fragment>
  );
}

export default ThankYou;
