import { useEffect } from "react";
import M from "materialize-css";
import { Link } from "react-router-dom";

function SideNav({ id }) {
  useEffect(() => {
    M.Sidenav.init(document.querySelector("#mobile-demo"));
  }, []);
  return (
    <ul className="sidenav" id="mobile-demo">
      <li>
        <div className="user-view center pv5">
          <div className="background justify-center pa3 valign-wrapper">
            <img
              className="w-50"
              src="https://storage.googleapis.com/corecdn-public/13302logo-side.png"
              alt="side logo"
            />
          </div>
        </div>
      </li>
      <li>
        <Link to="/">HOME</Link>
      </li>
      <li>
        <Link to="/contact">RESERVATION INQUIRIES</Link>
      </li>
      <li>
        <Link to="/contact">CONTACT</Link>
      </li>
    </ul>
  );
}

export default SideNav;
